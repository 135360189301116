import PictureFolderMenu from './menu';
import { ChanelModel, ImageModel, ImagesService } from '../../../../services/images.service';
import { ScrapperChanel } from '../../scrapper/chanel';
import { Image } from './image';
import style from './style.module.css';
import PictureFolderHeader from './header';
import { Component, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

interface State {
  channels: ChanelModel[];
  images: ImageModel[];
  imagesComponents: any[];
  deleteEnabled: boolean;
}

/**
 * Component for rendering pictures based on url property where should be folder ID.
 */
export default function PictureFolder() {
  let { id } = useParams();
  // constructor(props: Props) {
  //   super(props);
  //   this.state = { channels: [], images: [], deleteEnabled: false, imagesComponents: [] };
  // }

  const [state, setState] = useState<State>({ channels: [], images: [], deleteEnabled: false, imagesComponents: [] });

  useEffect(() => {
    console.log(`loading images ${id}`);
    ImagesService.loadFolder(id || '').then((images: ImageModel[]) => {
      setState({ ...state, images });
    });
  }, []);

  const toggleDelete = () => {
    setState({ ...state, deleteEnabled: !state.deleteEnabled });
  };

  const onRemoval = (imageToDelete: ImageModel) => {
    if (!id) {
      console.log('No picture folder id, no request :)');
      return;
    }
    ImagesService.removeImage(imageToDelete._id, id).then((value) => {
      console.log({ value });
      if (value) {
        const findIndex = state.images.findIndex((imageToFind) => imageToFind._id === imageToDelete._id);
        const images = state.images;
        images.splice(findIndex, 1);
        setState({ ...state, images });
      }
    });
  };

  const newPictureUploaded = (uploadedPictures: ImageModel[]) => {
    console.log({ uploadedPictures });
    setState({ ...state, images: [...uploadedPictures, ...state.images] });
  };

  const images: any = [];
  state.images.forEach((image) => {
    images.push(
      <Image
        key={image._id}
        image={image}
        canDelete={state.deleteEnabled}
        onRemoval={() => {
          onRemoval(image);
        }}
      />,
    );
  });

  return (
    <div>
      <PictureFolderHeader folderId={id} pictures={state.images.length} />

      <div className={style.imageContainerWrapper}>
        <div className={style.imageContainer}>
          {images}
          <div className="footerSpacer"> </div>
        </div>
      </div>

      <PictureFolderMenu
        pictureFolderId={id}
        toggleDelete={toggleDelete}
        deleteEnabled={state.deleteEnabled}
        newPictureUploaded={newPictureUploaded}
      />
    </div>
  );
}
