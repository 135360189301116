import style from '../../../components/menu/menuStyle.module.css';
import { AuthorizationService } from '../../../services/authorization.service';
import { Component } from 'react';

export default class InstructionsMenu extends Component<any, any> {
  private mailto: string;

  constructor(p: any) {
    super(p);
    const email = 'sample@gmail.com';
    const subject = 'Test';
    const emailBody = 'Hi Sample,';
    const attach = 'path';
    this.mailto = 'mailto:' + email + '?subject=' + subject + '&body=' + emailBody + '?attach=' + attach;
  }

  sendEmail = () => {};

  logOut = () => {
    AuthorizationService.signout().then((value) => {
      // route('/login');
    });
  };

  render() {
    return (
      <div className={style.newMain}>
        <div className={style.a} style={{ fontSize: '15px', textDecoration: 'underline', flexDirection: 'column-reverse' }}>
          <a href={this.mailto}>Email us (ask me anything)</a>
          <span onClick={this.logOut} style={{ cursor: 'pointer' }}>
            Log out (see you soon)
          </span>
        </div>

        <div className={style.c}></div>
      </div>
    );
  }
}
