import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { userReducer } from '../model/user_reducer';
import { IRootState } from '../model/store';

const PrivateRoutes = () => {
  const user = useSelector((state: IRootState) => state.userReducer);
  const location = useLocation();
  // console.log(`user is ${user.authenticated ? 'authenticated' : 'not authenticated'}`);

  return user.authenticated ? <Outlet /> : <Navigate to={`/login?prevLoc=${location.pathname}`} />;
};

export default PrivateRoutes;
