import WordFolderHeader from './header';
import WordFolderMenu from './menu';
import style from './style.module.css';
import { WordModel, WordsService } from '../../../../services/words.service';
import Word from '../../word';
import { Component, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

interface State {
  word?: WordModel;
  wordsCount: number;
}

/**
 * Component for putting words into database. This show & edit exactly one word get by folder ID
 */
export default function WordFolder() {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  let { wordFolderId } = useParams();

  const [state, setState] = useState<State>({ wordsCount: 0 });
  let [input, setInput] = useState('');

  useEffect(() => {
    if (wordFolderId) {
      WordsService.getByFolder(wordFolderId)
        .then((word) => {
          const wordsCount = word.content !== '' ? word.content.split(' ').length : 0;
          setState({ word, wordsCount });
          setInput(word.content);
        })
        .catch((reason) => {
          if (wordFolderId) {
            WordsService.add(wordFolderId, '')
              .then((word) => {
                setState({ ...state, word });
              })
              .catch((reason1) => {
                console.log({ reason1 });
              });
          }
        });
    } else {
      console.log('no folder id so no requests');
    }
  }, []);

  const onInput = (e: any) => {
    const { value } = e.target;
    setInput(value);
    const wordsCount = input !== '' ? input.split(' ').length : 0;
    if (state.wordsCount !== wordsCount) {
      setState({ ...state, wordsCount });
    }
  };

  const onRoute = () => {
    if (state.word) {
      WordsService.update(state.word._id, input).then((saved) => {
        console.log({ saved });
      });
    }
  };

  return (
    <div style={{ height: '100%' }}>
      <WordFolderHeader onRouterChange={onRoute} wordCount={state.wordsCount} />

      <div className={style.container}>
        <textarea
          autoFocus
          ref={textAreaRef}
          className={style.text}
          placeholder="Enjoy that new doc smell"
          value={input}
          onInput={onInput}
        />
      </div>

      <WordFolderMenu onRouterChange={onRoute} />
    </div>
  );
}
