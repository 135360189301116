import style from './style.module.css';
import HomeMenu from './menu';
import Navigation from '../../../components/navigation';
import { AuthorizationService } from '../../../services/authorization.service';
import { FolderModel, FoldersService, FolderType } from '../../../services/folders.service';
import DeleteButton from '../../../components/deleteButton/deleteButton';
import { Component, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface State {
  folders: FolderModel[];
  canDelete: boolean;
}

export default function LoggedInHome() {
  // constructor(p: any) {
  //   super(p);
  //   this.state = { canDelete: false, folders: [] };
  // }
  const [state, setState] = useState<State>({ canDelete: false, folders: [] });
  let navigate = useNavigate();

  useEffect(() => {
    FoldersService.get().then((folders: FolderModel[]) => {
      setState({ ...state, folders });
      console.log({ folders });
    });
  }, []);

  const onDelete = () => {
    setState({ ...state, canDelete: !state.canDelete });
  };

  const onAdd = (type: FolderType) => {
    let highestNumber = 0;
    if (state.folders) {
      state.folders.forEach((folder: FolderModel) => {
        if (folder.type === type) {
          // is the right type
          if (highestNumber < folder.id) {
            highestNumber = folder.id;
          }
        }
      });
    }

    highestNumber++; // make it by one bigger
    FoldersService.add(highestNumber.toString(), type).then((newFolder: any) => {
      if (newFolder.type === '0') {
        newFolder.type = FolderType.WORD;
      } else if (newFolder.type === '1') {
        newFolder.type = FolderType.IMAGE;
      }
      setState({ ...state, folders: [...state.folders, newFolder] });
    });
  };

  const routeTo = (path: string) => {
    if (!state.canDelete) {
      // only route if delete is not enabled
      navigate(path);
    }
  };

  const onDeleteClick = (id: string) => {
    console.log({ id });
    FoldersService.delete(id).then((result) => {
      if (result) {
        const findIndex = state.folders.findIndex((folderToFind) => folderToFind._id === id);
        const folders = state.folders;
        folders.splice(findIndex, 1);
        setState({ ...state, folders });
      }
    });
  };

  let folders: any = [];
  if (state.folders) {
    state.folders.forEach((folder: FolderModel, index: number) => {
      let folderElm: any;
      if (folder.type === FolderType.WORD) {
        folderElm = (
          <div key={'folder-' + folder._id}>
            {state.canDelete ? (
              <DeleteButton
                delete={() => {
                  onDeleteClick(folder._id);
                }}
              />
            ) : (
              ''
            )}
            <div
              className={style.box}
              onClick={() => {
                routeTo('/word/' + folder.id);
              }}
            >
              Word {folder.id !== 1 ? folder.id : ''}
            </div>
          </div>
        );
      } else if (folder.type === FolderType.IMAGE) {
        folderElm = (
          <div key={'folder-' + folder._id}>
            {state.canDelete ? (
              <DeleteButton
                delete={() => {
                  onDeleteClick(folder._id);
                }}
              />
            ) : (
              ''
            )}
            <div
              className={style.box}
              onClick={() => {
                routeTo('/picture/' + folder.id);
              }}
            >
              Picture {folder.id !== 1 ? folder.id : ''}
            </div>
          </div>
        );
      } else {
        folderElm = (
          <div className={style.box} key={'folder-' + folder._id}>
            ERROR
          </div>
        );
      }
      folders.push(folderElm);
    });
  }

  // wrap every folder with wrapper for propper 50%
  folders = folders.map((folder: any) => <div className={style.boxWrapper}>{folder}</div>);

  return (
    <div>
      <Navigation />

      <div>
        <div className={style.home}>{folders}</div>

        <HomeMenu onAdd={onAdd} onDelete={onDelete} canDelete={state.canDelete} />
      </div>
      <div className="footerSpacer"> </div>
    </div>
  );
}
