import style from './style.module.css';
import { GenerationModel, TShirtColors, TShirtTypes } from '../generationModel';
import { ImageModel, LoadedChanelModel } from '../../../../services/images.service';
import ImagePlacer from './imagePlacer';
import { WordModel } from '../../../../services/words.service';
import { Component, useEffect, useState } from 'react';

export interface DrawArea {
  x: number;
  y: number;
  height: number;
  width: number;
  ratio?: number;
}

interface State {
  drawArea: DrawArea;
}

export interface ToolCoreProps {
  model: GenerationModel;
  admin: boolean;
}

export default function TheToolCore(props: ToolCoreProps) {
  // constructor(props: ToolCoreProps) {
  //   super(props);
  //   this.state = { drawArea: {} as DrawArea };
  //   this.recalculateDrawArea();
  // }
  const [state, setState] = useState({ drawArea: {} as DrawArea });

  useEffect(() => {
    recalculateDrawArea();
  }, []);

  // TODO: fix canvas size change
  // useEffect(() => {
  //   recalculateDrawArea();
  // }, [props.model.canvasWidth, props.model.canvasHeight]);
  //
  // componentDidUpdate(previousProps: Readonly<ToolCoreProps>) {
  //   if (
  //     previousProps.model.canvasWidth !== this.props.model.canvasWidth ||
  //     previousProps.model.canvasHeight !== this.props.model.canvasHeight
  //   ) {
  //     // check if props updated
  //     this.recalculateDrawArea();
  //   }
  // }

  const recalculateDrawArea = () => {
    const ratio = props.model.canvasWidth / props.model.canvasHeight;

    const maxW = 325;
    const maxH = 450;

    const w = 320;
    const he = ratio * 320;

    if (w > maxW) {
      console.log('Width overflow');
    }

    if (he > maxH) {
      console.log('Height overflow');
    }

    setState({
      drawArea: {
        x: 0,
        y: 0,
        height: he,
        width: w,
        ratio,
      },
    });
  };

  const getDrawArea = () => {
    if (!props.admin) {
      return;
    }
    if (state && state.drawArea && state.drawArea.ratio && props.model.drawAreaVisible) {
      const style: any = {
        fill: 'white',
        stroke: 'black',
        strokeWidth: '2px',
        strokeDasharray: '3px',
      };
      if (props.model.tShirtColor === TShirtColors.DARK) {
        style.fill = 'black';
        style.stroke = 'white';
      }

      return <rect x={440} y={150} width={state.drawArea.width} height={state.drawArea.height} style={style} />;
    }
  };

  const getLotNumbers = () => {
    if (!props.admin) {
      return;
    }
    if (props.model.lotNumbers) {
      return (
        <g>
          <text textAnchor="start" x="595" y="140" className={style.lotText}>
            2
          </text>
          <text textAnchor="start" x="420" y="270" className={style.lotText}>
            4
          </text>
          <text textAnchor="start" x="775" y="270" className={style.lotText}>
            6
          </text>
          <text textAnchor="start" x="595" y="600" className={style.lotText}>
            0
          </text>
        </g>
      );
    }
  };

  const getTShirtFile = () => {
    let tShirtFile = 'shirt';
    switch (props.model.tShirtType) {
      case TShirtTypes.LONGSLEEVE:
        break;
      case TShirtTypes.TSHIRT:
        tShirtFile += '_short';
        break;
    }

    switch (props.model.tShirtColor) {
      case TShirtColors.DARK:
        tShirtFile += '_dark';
        break;
      case TShirtColors.LIGHT:
        break;
    }
    tShirtFile += '.svg';
    return tShirtFile;
  };

  if (!props.admin) {
    return <ImagePlacer model={props.model} drawArea={state.drawArea} />;
  } else {
    return (
      <div className={style.container}>
        <svg width="1200" height="1000">
          <image x={0} y={0} href={getTShirtFile()} height="700" width="1200" />
          {getDrawArea()}
          {getLotNumbers()}
          {/*<text x="20" y="20" style={{ fontSize: "20px" }}>*/}
          {/*    Ratio: {this.state.drawArea ? this.state.drawArea.ratio : " "}*/}
          {/*</text>*/}
          <ImagePlacer model={props.model} drawArea={state.drawArea} />
        </svg>
      </div>
    );
  }
}
