import style from './style.module.css';
import { AuthorizationService } from '../../services/authorization.service';
import menuStyle from '../../components/menu/menuStyle.module.css';
import { store } from '../../model/store';
import { isMobile } from '../../components/utils/screen';
import LoginHeader from './header';
import { anonymousPrefix } from '../../components/utils/global';
import { Component } from 'react';

interface LoginState {
  username?: string;
  password?: string;
  passwordHint?: string;
}

const passwordMessage = 'not needed';
const username = 'nobody #10156';

/**
 * Component for logging in
 */

export default class Login extends Component<any, LoginState> {
  constructor(props: any) {
    super(props);
    this.state = {
      username: username,
      password: '',
      passwordHint: passwordMessage,
    };
  }

  /**
   * Determine if we are guest or already visited user
   */
  componentDidMount() {
    this.setState({ username: anonymousPrefix + store.getState().guestReducer.id }); // update username

    store.subscribe(() => {
      if (store.getState().guestReducer.id !== 0) {
        this.setState({ username: anonymousPrefix + store.getState().guestReducer.id }); // update username
      }
    });
  }

  /**
   * On auth ask backend for user profile
   * @param e
   */
  onSubmit = (e: any) => {
    e.preventDefault();
    // console.log(this.state);
    if (store.getState().userReducer.authenticated) {
      // route('/home', true);
    }
    var pass = this.state.password;
    if (this.state.username?.includes(anonymousPrefix)) {
      pass = 'dummy';
    }
    AuthorizationService.authenticate(this.state.username || '', pass || '').then((result) => {
      console.log({ result });
      if (result == undefined) {
        // wrong password
      } else {
        // route('/home', true);
      }
    });
  };

  onPasswordChange = (e: any) => {
    this.setState({ password: e.target.value });
  };

  onNameChange = (e: any) => {
    let newUsername = e.target.value;

    if (this.state.username !== username) {
      this.setState({ passwordHint: 'password' });
    } else {
      this.setState({ passwordHint: passwordMessage });
    }

    this.setState({ username: newUsername });
  };

  render() {
    return (
      <div>
        <LoginHeader />
        <form onSubmit={this.onSubmit} className={style.form}>
          <div className={style.loginForm}>
            <input
              className={style.loginInput}
              type="text"
              placeholder="username"
              value={this.state.username}
              onInput={this.onNameChange}
            />
            <input
              className={style.loginInput}
              type="password"
              placeholder={this.state.passwordHint}
              value={this.state.password}
              onInput={this.onPasswordChange}
            />

            {/*<input type="text" value={this.state.password} onInput={this.onPasswordChange} />*/}
          </div>

          <div className={menuStyle.newMain}>
            <button type="submit" className={style.loginButton}>
              {isMobile() ? 'Continue' : 'Continue to beta'}
            </button>
          </div>
        </form>
      </div>
    );
  }
}
