import style from '../../../components/header/style.module.css';
import menuStyle from '../../../components/menu/menuStyle.module.css';
import { store } from '../../../model/store';
import { anonymousPrefix } from '../../../components/utils/global';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {}

const SendHeader: FC<Props> = (props: Props) => {
  const navigate = useNavigate();
  const userId = anonymousPrefix + store.getState().guestReducer.id;

  return (
    <div className={style.main}>
      <div className={style.a} style={{ width: '123px' }}>
        {' '}
      </div>
      <div className={style.b}>{userId}, thanks for trying the beta version ✨</div>
      <div className={style.c}>
        <button
          type="submit"
          className={menuStyle.menuButton}
          onClick={() => {
            navigate('/home');
          }}
        >
          Back
        </button>
      </div>
    </div>
  );
};

export default SendHeader;
