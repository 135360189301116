import style from './style.module.css';
import { AuthorizationService } from '../../services/authorization.service';
import { hasGotTheirOwnMenu, hasTheirOwnHeader } from '../utils/global';
import { store } from '../../model/store';
import { Component } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export default function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const logout = () => {
    AuthorizationService.signout().then(() => {
      navigate('/');
    });
  };

  if (hasTheirOwnHeader.includes(location.pathname)) {
    return <></>;
  }

  for (var i = 0; i < hasTheirOwnHeader.length; i++) {
    if (location.pathname.includes(hasTheirOwnHeader[i])) {
      return <></>;
    }
  }

  var user = store.getState().userReducer;

  if (store.getState().userReducer.authenticated) {
    return (
      <div className={style.main}>
        <div className={style.a}> </div>
        <div
          className={style.b}
          onClick={() => {
            navigate('/home');
          }}
        >
          ~{user.email}, X folders, unset medium
        </div>
        <div className={style.c}>
          <a href="/profile">Edit profile</a>
          <a href="#" onClick={logout}>
            Logout
          </a>
        </div>
      </div>
    );
  } else {
    return (
      <div className={style.main}>
        <div className={style.a}></div>
        <div className={style.b}>
          Hello, for early access please just continue to beta as <a href="">nameless nobody #10156</a>
        </div>
        <div className={style.c}>
          <a href="/register">Sign up</a>
          <a href="/login">Log in</a>
        </div>
      </div>
    );
  }
}
