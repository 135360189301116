import style from '../../../../components/menu/menuStyle.module.css';
import { Component } from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
  onRouterChange: () => void;
}

export default function WordFolderMenu(props: Props) {
  let navigate = useNavigate();
  return (
    <div className={style.newMain}>
      <div className={style.restButtons}>
        <button
          type="submit"
          className={style.menuButton + ' wireButton'}
          onClick={() => {
            props.onRouterChange();
            navigate('/send');
          }}
        >
          Send
        </button>
      </div>
    </div>
  );
}
