import style from '../../../components/menu/menuStyle.module.css';
import { Component } from 'react';

export default class WordMenu extends Component<any, any> {
  render() {
    return (
      <div className={style.main}>
        <div className={style.a}>
          <button
            type="submit"
            className={style.menuButton}
            onClick={() => {
              // route('/home');
            }}
          >
            Back to home
          </button>
        </div>

        <div className={style.c}>
          <button type="submit" className={style.menuButton + ' grayButton'}>
            Shortcuts
          </button>
          <button type="submit" className={style.menuButton + ' wireButton'}>
            Picture
          </button>
        </div>
      </div>
    );
  }
}
