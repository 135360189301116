import style from '../../../../components/menu/menuStyle.module.css';
import DropdownMenu from '../../../../components/dropdown';
import { ImageModel, ImagesService } from '../../../../services/images.service';
import { Component, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
  pictureFolderId?: string;
  deleteEnabled: boolean;
  toggleDelete: () => void;
  newPictureUploaded: (newPictures: ImageModel[]) => void;
}

export default function PictureFolderMenu(props: Props) {
  const inputFile = useRef(null);
  const input: File = new File([], '', undefined);
  let navigate = useNavigate();

  const addMenu = [
    {
      label: 'Upload image',
      action: () => {
        onButtonClick();
      },
    },
    {
      label: 'Paste link (URL, Are.na)',
      action: () => {
        navigate('/picture/' + props.pictureFolderId + '/add');
      },
    },
  ];

  const onButtonClick = () => {
    console.log({ inputFile });
    // `current` points to the mounted file input element
    if (inputFile.current !== null) {
      // @ts-ignore
      inputFile.current.click();
    }
  };

  const handleChange = (e: any) => {
    if (e && e.target && e.target.files && e.target.files.length > 0 && props.pictureFolderId) {
      let fileList = e.target.files;
      ImagesService.uploadImages(fileList, props.pictureFolderId).then((value) => {
        props.newPictureUploaded(value);
      });
    }
  };

  return (
    <div className={style.newMain}>
      {/*<button type="submit" class={style.menuButton + ' ' + style.mainMenuButton} onClick={()=> {route("/home")}}>Modules</button>*/}

      <div className={style.restButtons}>
        <DropdownMenu
          label="Add"
          buttons={addMenu}
          horizontalPosition="CENTER"
          buttonStyling={style.menuButton}
        ></DropdownMenu>
        <button
          type="submit"
          className={style.menuButton + ' ' + (props.deleteEnabled ? 'grayButton' : 'wireButton')}
          onClick={props.toggleDelete}
        >
          Delete
        </button>
        <button
          type="submit"
          className={style.menuButton + ' wireButton'}
          onClick={() => {
            navigate('/send');
          }}
        >
          Send
        </button>
      </div>

      <input
        type="file"
        id="file"
        ref={inputFile}
        onChange={(e: any) => handleChange(e)}
        style={{ display: 'none' }}
        multiple
      />
    </div>
  );
}
