import style from '../../components/header/style.module.css';
import { store } from '../../model/store';
import { FC } from 'react';

interface Props {}

const LoginHeader: FC<Props> = (props: Props) => {
  const userId = store.getState().guestReducer.id;

  return (
    <div className={style.main}>
      <div className={style.a}> </div>
      <div className={style.b}>Hello, for early access, continue to beta as Anonymous #{userId}</div>
      <div className={style.c}> </div>
    </div>
  );
};

export default LoginHeader;
