import style from '../../../components/menu/menuStyle.module.css';
import DropdownMenu from '../../../components/dropdown';
import { FolderType } from '../../../services/folders.service';
import { Component } from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
  onAdd: (type: FolderType) => void;
  onDelete: () => void;
  canDelete: boolean;
}

export default function HomeMenu(props: Props) {
  const navigate = useNavigate();

  const addMenu = [
    {
      label: 'Picture',
      action: () => {
        props.onAdd(FolderType.IMAGE);
      },
    },
    {
      label: 'Word',
      action: () => {
        props.onAdd(FolderType.WORD);
      },
    },
  ];

  return (
    <div className={style.newMain}>
      {/*<button*/}
      {/*  type="submit"*/}
      {/*  className={style.menuButton + ' ' + style.mainMenuButton}*/}
      {/*  onClick={() => {*/}
      {/*    navigate('/home');*/}
      {/*  }}*/}
      {/*>*/}
      {/*  Modules*/}
      {/*</button>*/}

      <div className={style.restButtons}>
        <DropdownMenu
          label="Add"
          buttons={addMenu}
          horizontalPosition="CENTER"
          buttonStyling={style.menuButton}
        ></DropdownMenu>
        {/*<button type="submit" className={style.menuButton + ' wireButton'}>*/}
        {/*  Add*/}
        {/*</button>*/}
        <button
          type="submit"
          className={style.menuButton + ' ' + (props.canDelete ? 'grayButton' : 'wireButton')}
          onClick={() => {
            props.onDelete();
          }}
        >
          Delete
        </button>
        <button
          type="submit"
          className={style.menuButton + ' wireButton'}
          onClick={() => {
            navigate('/send');
          }}
        >
          Send
        </button>
      </div>
    </div>
  );
}
