import style from './style.module.css';
import { CompositionTypes, GenerationModel, PrintColors, TShirtColors } from '../generationModel';
import Hider from '../../../../components/hider';
import { Component, useState } from 'react';

interface Props {
  model: GenerationModel;
  modelChange: (key: string, value: any) => void;
}

const booleans = ['drawAreaVisible', 'lotNumbers', 'qrCode'];

export default function UserModelPicker(props: Props) {
  // constructor(props: Props) {
  //   super(props);
  //   this.state = props;
  // }

  const [state, setState] = useState(props);

  const handleInputChange = (event: any) => {
    const target = event.target;
    const name = target.name;

    const value = booleans.includes(target.name) ? target.checked : target.value;
    //
    console.log({ value });
    props.modelChange(name, value);

    // this.props.model[name]
    // this.setState({
    //     [name]: value
    // });
  };

  const changeTshirtColor = (event: any) => {
    const newColor: TShirtColors =
      event.target.value === TShirtColors.DARK.toString() ? TShirtColors.DARK : TShirtColors.LIGHT; // TODO: well...
    props.modelChange('tShirtColor', newColor);
    setState({
      ...state,
      model: {
        ...state.model,
        tShirtColor: newColor,
      },
    });
  };

  const changePrintColor = (event: any) => {
    const newColor: PrintColors =
      event.target.value === PrintColors.COLORFULL.toString() ? PrintColors.COLORFULL : PrintColors.BW; // TODO: well...
    props.modelChange('printColor', newColor);
    setState({
      ...state,
      model: {
        ...state.model,
        printColor: newColor,
      },
    });
  };

  const changeComposition = (event: any) => {
    const composition: CompositionTypes =
      event.target.value === CompositionTypes.RANDOM.toString() ? CompositionTypes.RANDOM : CompositionTypes.GRID; // TODO: well...
    props.modelChange('composition', composition);
    setState({
      ...state,
      model: {
        ...state.model,
        composition: composition,
      },
    });
  };

  return (
    <div>
      <Hider headline={'Medium'} visible={true}>
        <div className={style.settingsLine}>
          <div className={style.settingsElem}> Specification #1: </div>
          <select
            className={style.input + ' ' + style.settingsElem}
            onChange={changeTshirtColor}
            value={state.model.tShirtColor}
          >
            <option value={TShirtColors.LIGHT}>White</option>
            <option value={TShirtColors.DARK}>Black</option>
          </select>
          <div className={style.settingsElemBig} style={{ color: '#A6A6A6' }}>
            {' '}
          </div>
        </div>
      </Hider>

      <Hider headline={'Print'} visible={true}>
        <div className={style.settingsLine}>
          <div className={style.settingsElem}> Color: </div>

          <select
            className={style.input + ' ' + style.settingsElem}
            onChange={changePrintColor}
            value={state.model.printColor}
          >
            <option value={PrintColors.COLORFULL}>Colorful picture</option>
            <option value={PrintColors.BW}>Black and white picture</option>
          </select>

          <input
            type="text"
            value={state.model.tShirtColor === TShirtColors.DARK ? 'White text' : 'Dark text'}
            className={style.input + ' ' + style.settingsElem}
            style={{ color: '#A6A6A6' }}
            disabled
          />
          <div className={style.settingsElem}> </div>
        </div>
        <div className={style.settingsLine}>
          <div className={style.settingsElem}> Composition: </div>
          <div className={style.settingsElemBig}>
            <label className={'radioContainer ' + style.radio}>
              Grid
              <input
                type="radio"
                value={CompositionTypes.GRID}
                onChange={changeComposition}
                checked={state.model.composition === CompositionTypes.GRID}
              />
              <span className="radioCheckmark"> </span>
            </label>
            <label className={'radioContainer ' + style.radio}>
              Random
              <input
                type="radio"
                value={CompositionTypes.RANDOM}
                onChange={changeComposition}
                checked={state.model.composition === CompositionTypes.RANDOM}
              />
              <span className="radioCheckmark"> </span>
            </label>
          </div>
          <div className={style.settingsElem} style={{ color: '#A6A6A6' }}>
            {' '}
          </div>
        </div>
        <div className={style.settingsLine} style={{ marginBottom: '0px' }}>
          <div className={style.settingsElem}> Canvas: </div>
          <input
            type="text"
            value={'A3'}
            className={style.input + ' ' + style.settingsElem}
            style={{ color: '#A6A6A6' }}
            disabled
          />

          <div className={style.settingsElemBig}> </div>
        </div>
      </Hider>
    </div>
  );
}
