import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
// import './App.css';
import AppFc from './components/appFc';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './model/store';
import './style/index.css';
import './style/checkboxes.css';
import './style/radios.css';
import './style/select.css';
import { AuthorizationService } from './services/authorization.service';

function App() {
  const [count, setCount] = useState(0);

  useEffect(() => {
    AuthorizationService.getSession().then(); // on app start get session from backend
  }, []);

  return (
    <Provider store={store}>
      <BrowserRouter>
        <AppFc />
      </BrowserRouter>
    </Provider>
  );
}

export default App;
