
export interface Constrains {

}

export const Constrains = {
    textDirection: true,
    maxUpscale: 3,
    minimumPictureWidth: 20,
    minimumTextSize: 8,
    maximumTextSize: 62
}
