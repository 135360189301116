import style from './style.module.css';
import {
  CompositionStrategies,
  CompositionTypes,
  GenerationModel,
  TShirtColors,
  TShirtTypes,
} from '../generationModel';
import Hider from '../../../../components/hider';
import { Component, useState } from 'react';

interface GenerationModelPickerProps {
  model: GenerationModel;
  modelChange: (key: string, value: any) => void;
}

const booleans = ['drawAreaVisible', 'lotNumbers', 'qrCode'];

export default function GenerationModelPicker(props: GenerationModelPickerProps) {
  // constructor(props: GenerationModelPickerProps) {
  //   super(props);
  //   this.state = props;
  // }
  const [state, setState] = useState(props);

  const handleInputChange = (event: any) => {
    const target = event.target;
    const name = target.name;

    const value = booleans.includes(target.name) ? target.checked : target.value;

    console.log({ value });
    props.modelChange(name, value);
    // const generationReducer = store.getState().generationReducer;
    //
    // store.dispatch(setGenerationAction());

    // this.props.model[name]
    // this.setState({
    //     [name]: value
    // });
  };

  const generateOptionsFromEnum = (enm: string[], modelParameter: string): any => {
    let enumResult: any = [];
    enm.forEach((type: string, index: number) => [
      enumResult.push(
        <label className="radioContainer" style={{ marginRight: '10px' }}>
          {type}
          <input
            type="radio"
            name={modelParameter}
            value={type}
            checked={index === (props.model as any)[modelParameter].valueOf()}
            onChange={(event) => {
              props.modelChange((event.target as any).name, index);
            }}
          />
          <span className="radioCheckmark"></span>
        </label>,
      ),
    ]);
    return enumResult;
  };

  const generate = () => {
    props.modelChange('generate', true);
  };

  let debugCheckboxes: any = [];
  booleans.forEach((paramName, index) => {
    debugCheckboxes.push(
      <label className="checkboxContainer" key={paramName}>
        {paramName}
        <input
          type="checkbox"
          name={paramName}
          checked={(props.model as any)[paramName]}
          onChange={handleInputChange}
        />

        <span className="checkmark"></span>
      </label>,
    );
  });

  const compositionTypes = generateOptionsFromEnum(
    Object.keys(CompositionTypes).filter((k) => typeof CompositionTypes[k as any] === 'number'),
    'composition',
  );
  // const tShirtTypes = this.generateOptionsFromEnum(Object.keys(TShirtTypes).filter(k => typeof TShirtTypes[k as any] === "number"), "tShirtType");
  // const tShirtColor = this.generateOptionsFromEnum(Object.keys(TShirtColors).filter(k => typeof TShirtColors[k as any] === "number"), "tShirtColor");
  // const compositionStrategy = this.generateOptionsFromEnum(Object.keys(CompositionStrategies).filter(k => typeof CompositionStrategies[k as any] === "number"), "compositionStrategy");

  return (
    <div>
      <Hider headline="DEBUG" visible={true}>
        DEBUG:
        {debugCheckboxes}
        {/*<div class={style.caption}>Composition strategy:</div>*/}
        {/*<div class={style.option}>*/}
        {/*    {compositionStrategy}*/}
        {/*</div>*/}
      </Hider>

      <Hider headline="Composition" visible={true}>
        <div className={style.settingsLine} style={{ marginBottom: '0px' }}>
          <div className={style.settingsElem}> Picture Count: </div>
          <input
            type="text"
            className={style.input + ' ' + style.settingsElem}
            value={props.model.picturesCount}
            onChange={(event: any) => {
              props.modelChange('picturesCount', parseInt(event.target.value));
            }}
          />

          <div className={style.settingsElemBig}> </div>
        </div>

        <div className={style.settingsLine} style={{ marginBottom: '0px' }}>
          <div className={style.settingsElem}> Word count: </div>
          <input
            type="text"
            className={style.input + ' ' + style.settingsElem}
            value={props.model.wordsCount}
            onChange={(event: any) => {
              props.modelChange('wordsCount', parseInt(event.target.value));
            }}
          />

          <div className={style.settingsElemBig}> </div>
        </div>

        {/*<div style={{display: 'flex'}}>*/}
        {/*    <div class={style.caption} style={{fontSize: '20px'}}>columnsCount:</div>*/}
        {/*    <input style={{fontSize: '20px'}} type="text" value={this.props.model.columnsCount} onChange={(event: any) => {this.props.modelChange('columnsCount', event.target.value)}} />*/}
        {/*</div>                */}
      </Hider>

      <button onClick={generate}>GENERATE</button>
    </div>
  );
}
