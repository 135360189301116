import style from './style.module.css';
import { ImageModel } from '../../../../services/images.service';
import DeleteButton from '../../../../components/deleteButton/deleteButton';
import { Component } from 'react';

export interface Props {
  image: ImageModel;
  canDelete: boolean;
  onRemoval: (img: ImageModel) => void;
}

export class Image extends Component<Props, any> {
  deletePic = () => {
    this.props.onRemoval(this.props.image);
  };

  render() {
    return (
      <span
        className={style.imageBox}
        style={{ backgroundImage: 'url(' + '/api/imagefiles/thumb/' + this.props.image.filename + ')' }}
      >
        {this.props.canDelete ? <DeleteButton delete={this.deletePic} /> : ''}
      </span>
    );
  }
}
