import { ImageModel } from '../../../services/images.service';
import style from './style.module.css';
import { Component } from 'react';

export interface ScrapperImageProps {
  image: ImageModel;
}

export class ScapperImage extends Component<ScrapperImageProps, any> {
  render() {
    return (
      <span className={style.imageBox}>
        <img className={style.image} src={'api/imagefiles/' + this.props.image.filename} />
      </span>
    );
  }
}
