import style from '../../../../components/header/style.module.css';
import menuStyle from '../../../../components/menu/menuStyle.module.css';
import { isMobile } from '../../../../components/utils/screen';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
  onRouterChange: () => void;
  wordCount: number;
}

const WordFolderHeader: FC<Props> = (props: Props) => {
  let navigate = useNavigate();
  return (
    <div className={style.main}>
      <div className={style.a}></div>
      <div
        className={style.b}
        onClick={() => {
          navigate('/home');
        }}
      >
        Word folder,
        {isMobile() ? <br /> : ''}
        {props.wordCount} words
      </div>
      <div className={style.c}>
        <button
          type="submit"
          className={menuStyle.menuButton}
          onClick={() => {
            props.onRouterChange();
            navigate('/home');
          }}
        >
          Back
        </button>
      </div>
    </div>
  );
};

export default WordFolderHeader;
