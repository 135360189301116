import menuStyle from '../../../../../components/menu/menuStyle.module.css';
import style from './style.module.css';
import { UrlCheck, UrlTypes } from '../../../../../components/utils/urlCheck';
import { ImageModel, ImagesService } from '../../../../../services/images.service';
import PictureFolderHeader from '../header';
import { Component, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

interface State {
  errMsg: string;
  imagesNum: number;
}

interface Props {
  pictureFolderId: string;
}

export default function PictureAdd() {
  let { pictureFolderId } = useParams();
  let navigate = useNavigate();
  // constructor(props: Props) {
  //   super(props);
  //   this.state = { errMsg: 'URL', imagesNum: 0 };
  // }

  const [state, setState] = useState({ errMsg: 'URL', imagesNum: 0 });
  const [input, setInput] = useState('');

  useEffect(() => {
    ImagesService.loadFolder(pictureFolderId || '').then((images: ImageModel[]) => {
      setState({ ...state, imagesNum: images.length });
    });
  }, []);

  const onSubmit = (e: any) => {
    e.preventDefault();
    let urlTypes = UrlCheck(input);
    console.log(urlTypes);
    if (urlTypes === UrlTypes.NOT_VALID) {
      setState({ ...state, errMsg: 'Not valid URL' });
    } else {
      setState({ ...state, errMsg: 'URL' });

      if (urlTypes === UrlTypes.ARENA && pictureFolderId) {
        const parts = input.split('/');

        let arenaId = parts[parts.length - 1];
        if (arenaId === '') {
          arenaId = parts[parts.length - 2];
        }

        console.log({ arenaId });

        ImagesService.scrap(arenaId, pictureFolderId).then((value) => {
          if (value) {
            // channel scrapped so lets move back
            navigate('/picture/' + pictureFolderId);
          } else {
            setState({ ...state, errMsg: 'Not valid URL' });
          }
        });
      } else if (urlTypes === UrlTypes.IMAGE) {
        console.log('simple img');
        setState({ ...state, errMsg: 'Not valid URL' });
      }

      // todo: load and move back to pic
    }
  };

  const onInput = (e: any) => {
    const { value } = e.target;
    setInput(value);
  };

  return (
    <div>
      <PictureFolderHeader folderId={pictureFolderId} pictures={state.imagesNum} returnToFolder={true} />

      <div className={style.container}>
        <div className={style.url}>{state.errMsg}</div>

        <textarea autoFocus className={style.text} rows={6} placeholder="" value={input} onInput={onInput} />
      </div>
      <div className={menuStyle.newMain}>
        <div className={menuStyle.restButtons}>
          <button type="submit" className={menuStyle.menuButton + ' wireButton'} onClick={onSubmit}>
            Add
          </button>
        </div>
      </div>
    </div>
  );
}
