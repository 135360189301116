import style from '../../../components/menu/menuStyle.module.css';
import DropdownMenu from '../../../components/dropdown';
import { Component } from 'react';

export default class SettingsMenu extends Component<any, any> {
  render() {
    return (
      <div className={style.newMain}>
        <div className={style.restButtons}>
          <button
            type="submit"
            className={style.menuButton + ' wireButton'}
            onClick={() => {
              // route('/send');
            }}
          >
            Send
          </button>
        </div>
      </div>
    );
  }
}
