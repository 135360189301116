import style from './menuStyle.module.css';
import { AuthorizationService } from '../../services/authorization.service';
import { store } from '../../model/store';
import { Component } from 'react';
import { Link } from 'react-router-dom';

export interface MenuProps {
  routeChange: string;
}

export default class Menu extends Component<MenuProps, any> {
  logout = () => {
    AuthorizationService.signout().then(() => {
      // route("/", true);
    });
  };

  render() {
    // TODO: clean default menu
    return;

    // if (!hasntGotTheirOwnMenu.includes(this.props.routeChange)){
    //     return ;
    // }
    if (store.getState().userReducer.authenticated) {
      return (
        <div className={style.main}>
          <div className={style.a}>
            <button type="submit" className={style.menuButton + ' grayButton'}>
              <Link to={'/instructions'}>Basic instructions</Link>
            </button>
          </div>

          <div className={style.c}>
            <button
              type="submit"
              className={style.menuButton + ' grayButton'}
              onClick={() => {
                // route('/settings');
              }}
            >
              Settings
            </button>
            <button
              type="submit"
              className={style.menuButton + ' wireButton'}
              onClick={() => {
                // route('/theTool');
              }}
            >
              The Tool
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <div className={style.main}>
          <div className={style.a}>
            <button
              type="submit"
              className={style.menuButton + ' grayButton'}
              onClick={() => {
                //route('/instructions')
              }}
            >
              Basic instructions
            </button>
          </div>

          <div className={style.c}>
            <button type="submit" className={style.menuButton + ' grayButton'}>
              Settings
            </button>
            <button type="submit" className={style.menuButton + ' wireButton'} disabled>
              Send
            </button>
          </div>
        </div>
      );
    }
  }
}
