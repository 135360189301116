import style from './style.module.css';
import { FC } from 'react';

interface Props {
  delete: () => void;
}

const DeleteButton: FC<Props> = (props: Props) => {
  return <button className={style.deleteButton} onClick={props.delete}></button>;
};

export default DeleteButton;
