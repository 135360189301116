import { ChanelModel, ImageModel, ImagesService } from '../../../services/images.service';
import style from './style.module.css';
import { ScapperImage } from './image';
import { Component } from 'react';

export interface ScrapperChanelProps {
  chanel: ChanelModel;
  onRemove: () => void;
}

export interface State {
  images: ImageModel[];
}

export class ScrapperChanel extends Component<ScrapperChanelProps, State> {
  componentDidMount() {
    ImagesService.loadChannelImages(this.props.chanel._id).then((value) => {
      this.setState({ images: value });
    });
  }

  onRemove = (e: any) => {
    console.log('removing');
    e.preventDefault();

    this.props.onRemove();
  };

  render() {
    const images: any = [];
    let len = 0;
    if (this.state.images) {
      this.state.images.forEach((value) => {
        images.push(<ScapperImage image={value} />);
      });
      len = this.state.images.length;
    }

    return (
      <div className={style.chanelBox}>
        <span>{this.props.chanel.url}</span>
        <button type="submit" className={style.removeButton + ' grayButton'} onClick={this.onRemove}>
          X
        </button>
        <p>{images}</p>
      </div>
    );
  }
}
