export const getRatio = (width: number, height: number): number => {
  return width / height;
};

export const getRandom = (min: number, max: number): number => {
  return Math.random() * (max - min) + min;
};

export const computeHeight = (width: number, ratio: number): number => {
  return width / ratio;
};

export const computeWidth = (height: number, ratio: number): number => {
  return height * ratio;
};

export const pix2mm = (pixels: number, dpi: number): number => {
  return pixels / 0.0393701 / dpi;
};

export const mm2pix = (mm: number, dpi: number): number => {
  return mm * (dpi / 25.4);
};
