import style from './menuStyle.module.css';
import { store } from '../../model/store';
import { isMobile } from '../utils/screen';
import { Component } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Navigation() {
  let navigate = useNavigate();

  let settingsStyle = style.deactivated,
    foldersStyle = style.deactivated,
    informationsStyle = style.deactivated;
  switch (window.location.pathname) {
    case '/settings':
      settingsStyle = style.active;
      break;
    case '/home':
      foldersStyle = style.active;
      break;
    case '/instructions':
      informationsStyle = style.active;
      break;
  }

  return (
    <div className={style.main}>
      <div className={style.a}> </div>

      <div className={style.b + ' ' + style.grayBack}>
        <div
          className={settingsStyle + ' ' + style.sideMenu}
          onClick={() => {
            navigate('/settings');
          }}
        >
          Settings
        </div>
        <div
          className={foldersStyle + ' ' + style.centerMenu}
          onClick={() => {
            navigate('/home');
          }}
        >
          Folders
        </div>
        <div
          className={informationsStyle + ' ' + style.sideMenu}
          onClick={() => {
            navigate('/instructions');
          }}
        >
          {isMobile() ? 'Info' : 'Informations'}
        </div>
      </div>

      <div className={style.c}></div>
    </div>
  );
}
