import { BrowserRouter, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Header from './header';
import LoggedInHome from '../routes/loggedIn/loggedInHome';
import Instructions from '../routes/loggedIn/instructions';
import Login from '../routes/login/login';
import Scrapper from '../routes/loggedIn/scrapper';
import Profile from '../routes/loggedIn/profile';
import Word from '../routes/loggedIn/word';
import PictureFolder from '../routes/loggedIn/directories/picture';
import PictureAdd from '../routes/loggedIn/directories/picture/add';
import WordFolder from '../routes/loggedIn/directories/word';
import Settings from '../routes/loggedIn/settings';
import TheTool from '../routes/loggedIn/theTool';
import NotFoundPage from '../routes/notfound';
import Menu from './menu';
import { AuthorizationService } from '../services/authorization.service';
import { IRootState, store } from '../model/store';
import PrivateRoutes from './PrivateRoutes';
import { useSelector } from 'react-redux';

export default function AppFc() {
  const location = useLocation();
  const navigate = useNavigate();
  const [locationHistory, setLocationHistory] = useState<string[]>([]);
  const user = useSelector((state: IRootState) => state.userReducer);

  useEffect(() => {
    // setLocationHistory([...locationHistory, location.pathname]);
    console.log({ locChange: location });
    if (user.authenticated && location.pathname === '/login') {
      const queryParams = new URLSearchParams(location.search);
      const prevLoc = queryParams.get('prevLoc');
      // navigate(state.urlBeforeRedirect, true);
      navigate(prevLoc || '/home');
    }
  }, [location, user]);

  useEffect(() => {
    // listen on store change to catch if user is autenticated
    store.subscribe(() => {
      // console.log(
      //   `take me hooome, country road ${store.getState().userReducer.authenticated} and ${location.pathname}`,
      // );
      // console.log({ location });
      if (store.getState().userReducer.authenticated && location.pathname === '/login') {
        const latestLoc = locationHistory[locationHistory.length - 1];
        if (latestLoc) {
          // console.log('hey we should redirect to ' + latestLoc);
          // navigate(latestLoc);
        } else {
          // console.log('hey we should redirect to \\home');
          // navigate('/home');
        }

        //     // navigate(state.urlBeforeRedirect, true);
        //     navigate('/home');
      }
    });
  }, []);

  return (
    <div id="app">
      <Header />

      <Routes>
        <Route path="/instructions" element={<Instructions />} />
        <Route path="/login" element={<Login />} />

        <Route element={<PrivateRoutes />}>
          <Route path="/" element={<LoggedInHome />} />
          <Route path="/scrapper" element={<Scrapper />} />
          <Route path="/profile/" element={<Profile user={'me'} />} />
          <Route path="/word" element={<Word />} />

          <Route path="/picture/:id" element={<PictureFolder />} />
          <Route path="/picture/:pictureFolderId/add" element={<PictureAdd />} />

          <Route path="/word/:wordFolderId" element={<WordFolder />} />

          <Route path="/settings" element={<Settings />} />
          <Route path="/theTool" element={<TheTool />} />
          <Route path="/send" element={<TheTool />} />
          <Route path="/profile/:user" element={<Profile user={'me'} />} />

          <Route path="/home" element={<LoggedInHome />} />
        </Route>
        {/*<NotFoundPage default />*/}
      </Routes>

      <Menu routeChange={location.pathname} />
    </div>
  );
}
