import { GenerationModel, TShirtColors, WordProps } from '../../../generationModel';
import { glob_generationModel } from '../../imagePlacer';
import { determineMultilineTextDimensions } from './helpers';
import { getWord } from '../content';
import { store } from '../../../../../../model/store';
import { FC } from 'react';

interface Props {
  w: WordProps;
}

const MultilineText: FC<Props> = (props: Props, key: string) => {
  const toDraw: any[] = [];

  let color = 'black';
  if (store.getState().generationReducer.tShirtColor === TShirtColors.DARK) {
    color = 'white';
  }

  props.w.lines.forEach((line, index) => {
    toDraw.push(
      <tspan x={props.w.x} dy="1.0em" key={line + '-' + index}>
        {line}
      </tspan>,
    );
  });

  const style = { fill: color, fontSize: props.w.fontSize + 'px', fontFamily: props.w.fontFamily };
  // 'fill: ' + color + ';' + 'font-size: ' + props.w.fontSize + 'px;' + 'font-family: ' + props.w.fontFamily + ';';

  return (
    <g key={key}>
      {/*<rect x={props.w.x} y={props.w.y} width={props.w.width} height={props.w.height} style="stroke: red; fill-opacity: 0" />*/}
      <text x={props.w.x} y={props.w.y} style={style}>
        {toDraw}
      </text>
    </g>
  );
};

export default MultilineText;
