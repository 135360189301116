import style from '../../../../components/header/style.module.css';
import menuStyle from '../../../../components/menu/menuStyle.module.css';
import { isMobile } from '../../../../components/utils/screen';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
  folderId?: string;
  pictures: number;
  returnToFolder?: boolean;
}

const PictureFolderHeader: FC<Props> = (props: Props) => {
  const navigate = useNavigate();

  return (
    <div className={style.main}>
      <div className={style.a} style={{ width: '123px' }}>
        {' '}
      </div>
      <div className={style.b}>
        Picture folder,
        {isMobile() ? <br /> : ''}
        {props.pictures} files
      </div>
      <div className={style.c}>
        <button
          type="submit"
          className={menuStyle.menuButton}
          onClick={() => {
            if (props.returnToFolder) {
              navigate('/picture/' + props.folderId);
            } else {
              navigate('/home');
            }
          }}
        >
          Back
        </button>
      </div>
    </div>
  );
};

export default PictureFolderHeader;
